import React, { useEffect, useState } from "react";
import "./App.css";
import moment from "moment";
import { json, Route, Routes, useLocation } from "react-router";
import trackVisitor from "./utilities/tracker";
import DaySection from "./js/daySection";
import FooterDrop from "./js/footerDrop";
import SattaKingInfo from "./js/SattaKingInfo";
import PageRoute from "./js/router";
import Footer from "./js/footer";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";
const momenttz = require("moment-timezone");

function App() {
  const [data, setData] = useState([]);
  const [datagame, setDataFor] = useState([]);
  const location = useLocation();
  const isContact = location.pathname.includes("/contact");
  const isDisc = location.pathname.includes("/disclaimer");
  const isPrivacy = location.pathname.includes("/privacy");
  const isAbout = location.pathname.includes("/about");
  const currentDate = moment().format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm");
  const prevDate = moment().subtract(1, "days").format("YYYY-MM-DD");

  useEffect(() => {
    trackVisitor();
  }, []);

  // get data base on current time and date
  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      // Convert current time to a moment object for comparison
      const currentMoment = moment(currentTime, "HH:mm");

      // Process and filter the data
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;

        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });

      // Sort the processed data by open_time
      const sortedProcessedData = processedData.sort((a, b) => {
        return a.itemTime.diff(b.itemTime);
      });

      // Separate records into those with available results and those with "wait"
      const availableResults = sortedProcessedData.filter(
        (item) => item.isAvailable
      );
      const upcomingRecords = sortedProcessedData.filter(
        (item) => !item.isAvailable
      );

      // Determine the records to display
      let recordsToDisplay = [];

      if (availableResults.length > 0) {
        // Show available results and include records up to the next upcoming record
        recordsToDisplay = [...availableResults];

        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        // No available results, show up to 3 upcoming records with "wait"
        recordsToDisplay = [...upcomingRecords.slice(0, 2)];
      }

      // Ensure only 3 records are shown
      if (recordsToDisplay.length > 3) {
        // Remove the oldest record if more than 3 records are present
        recordsToDisplay = recordsToDisplay.slice(-2);
      }

      // Update state with the processed and limited data
      setDataFor(recordsToDisplay);

      // Debugging log
    }
  }, [data, currentTime]);

  // scroll navigation
  function pageScroll() {
    // const daySection = document.getElementById('dayResult');
    const tableSection = document.getElementById("monthTable");
    // if (daySection) {
    //   daySection.scrollIntoView({ behavior: 'smooth' });
    // }
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <div className="App">
      {/* seo setup start */}
      <Helmet >
        <title>App - Satta Fast King</title>
        <meta name="description" content="This is the page for getting all details." />
        <meta name="Keywords"
          content="sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king," />
        <link rel="canonical" href="https://sattakingrecords.co/" />
      </Helmet>
      {/* seo setup end */}
      <div className="col-12">
        <div className="row text-center">
          <a href="/" className="col-md-3 nav_link col-sm-12">
            HOME
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              pageScroll();
            }}
            href=""
            className="col-md-3 nav_link col-sm-12"
          >
            SATTA CHART
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              pageScroll();
            }}
            href=""
            className="col-md-3 nav_link col-sm-12"
          >
            SATTA 2023
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              pageScroll();
            }}
            href=""
            className="col-md-3 nav_link col-sm-12"
          >
            OLD CHART
          </a>
        </div>
      </div>
      {!(isAbout || isContact || isDisc || isPrivacy) && (
        <>
          <div className="heading">
            <h1 className="text-center mt-2 p-2">SATTA KING</h1>
          </div>
          <marquee className="text-white fw-bold">
            ध्यान रहे :: हम यहाँ सट्टा नंबर की Guessing/भविष्यवाणी करके गेम
            बनाकर देते हैं । हमारा किसी भी कंपनी से कोई लेना देना नहीं है । आप
            अपनी समझदारी से पैसे का लेन-देन करें । सबके हित में जारी ।
          </marquee>
          <AdvertisementComponent type='odd' />
          <marquee className="text-white fw-bold">
            sattakingrecords.com, satta king, sattaking, satta king 2024, satta
            king live, Satta result, Satta king result, Satta king online, Gali
            result, disawar result, satta king chart, satta king live, gali
            satta, disawar live result, disawar satta, disawar satta result,
            disawar satta king, disawar result today, gali satta, gali satta
            king, gali live result, satta matka, satta matka king, up satta
            king, Satta king 2024 chart, gali live result, desawar live result,
            satta king record, old taj, old taj satta, black satta, chotu taj,
            black satta king, meerut city satta, faridabad result, faridabad
            satta result, play bazaar, satta record live, delhi bazaar satta
            result
          </marquee>
          <div className="dayContent text-center">
            <h3 className="">TODAY LIVE RESULT</h3>
            <h6>
              <em className="text-white">
                हा भाई यही आती हे सबसे पहले खबर रूको और देखो
              </em>
            </h6>
            <h6>
              <em className="text-white">SUPER FAST RESULT</em>
            </h6>

            <div className="container">
              <div className="row justify-content-between align-items-center">
                {datagame?.length ? (
                  datagame.map((todayData, index) => (
                    <div
                      className="col-md-5 d-flex flex-column align-items-center"
                      key={index}
                    >
                      <div className="game-info">
                        <h3 className="mb-0">{todayData?.gameName}</h3>
                        <h6 className="text-result">
                          <p className="blinking-text">
                            {todayData?.result || "wait"}
                          </p>
                        </h6>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-md-12 text-center">
                    <h3 className="mb-0">No Data Available</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <DaySection dayGameData={data} />
      <AdvertisementComponent type='even' />
      <FooterDrop />
      <AdvertisementComponent type='random' />
      <SattaKingInfo />
      <PageRoute />
      <Footer />
    </div>
  );
}

export default App;
